export default {
  URL_ROOT: 'https://capslook.studio', // 'http://localhost',
  API_ROOT: 'https://capslook.studio/v3/', // 'http://localhost/v3/',
  GOOGLE_API_KEY: 'AIzaSyCNnBNvgWnZkzpW9o-MQuqbeRzcEUue748', //AIzaSyADVo1qLRNVVLYZG7CdVhhqs5vRHgf0Dv0
  NOTIFY_TIMEOUT: 60000, //ms
  PER_TO_PAGE: 10,
  endpoints: {
    login: 'authenticate',
    me: 'me',
  },
  getPlayerStatus: device => {
    if( !device.activated ) return 'inactive'
    else if( !device.online ) return 'offline'
    else return 'online'
  }
}
